import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import buildingAPIService from "./buildingAPIService";


const initialState = {
    buildings: [],
    building: {},
    isError: false,
    isLoading: false,
    isSuccess: false,
    message: ''
};


//create building
export const createBuilding = createAsyncThunk('buildings/create',
    async (buildingData, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;
            return await buildingAPIService.createBuilding(buildingData, token);
        } catch (error) {
            const message =
                error.message ||
                error.toString() ||
                error.response?.data?.name[0];

            // return thunkAPI.rejectWithValue({ error: message });
            throw new Error(message);
        }
    }
)

//update building
export const updateBuilding = createAsyncThunk('buildings/update',
    async ({ buildingData, barcode }, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;
            return await buildingAPIService.updateBuilding(buildingData, barcode, token);
        } catch (error) {
            const message =
                error.message ||
                error.toString() ||
                error.response?.data?.name[0];

            // return thunkAPI.rejectWithValue({ error: message });
            throw new Error(message);
        }
    }
)

//get all buildings
export const getBuildings = createAsyncThunk('buildings/getAll',
    async (_, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;
            return await buildingAPIService.getBuildings(token);
        } catch (error) {
            const message =
                error.message ||
                error.toString() ||
                error.response?.data?.name[0];

            // return thunkAPI.rejectWithValue({ error: message });
            throw new Error(message);
        }
    }
);

export const buildingSlice = createSlice({
    name: 'building',
    initialState,
    reducers: {
        reset: (state) => initialState
    },
    extraReducers: (builder) => {
        builder
            //create building
            .addCase(createBuilding.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.isSuccess = false;
                state.message = '';
            })
            .addCase(createBuilding.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.building = action.payload;
                state.message = 'Building created successfully';
            })
            .addCase(createBuilding.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message;
            })
            //update building
            .addCase(updateBuilding.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.isSuccess = false;
                state.message = '';
            })
            .addCase(updateBuilding.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.building = action.payload;
                state.message = 'Building updated successfully';
            })
            .addCase(updateBuilding.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message;
            })
            //get all buildings
            .addCase(getBuildings.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getBuildings.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.buildings = action.payload.results;
                state.message = "";
            })
            .addCase(getBuildings.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message;
            })
    },
});

export const { reset } = buildingSlice.actions;
export default buildingSlice.reducer;