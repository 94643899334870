import axios from "axios";

const CREATE_PROJECT_URL = "/api/v1/entities/projects/";
const GET_PROJECTS_URL = "/api/v1/entities/projects/";

//get projects
const getProjects = async (token) => {
    const response = await axios.get(GET_PROJECTS_URL, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    });
    return response.data
};


//create project
const createProject = async (projectData, token) => {
    const config = {
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    };

    const response = await axios.post(CREATE_PROJECT_URL, projectData, config);
    return response.data;
};

const projectAPIService = { createProject, getProjects };

export default projectAPIService;