import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { Formik } from 'formik';
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box, Button, IconButton, MenuItem, TextField } from "@mui/material";
import FormikDatePicker from "components/FormikDatePicker";
import moment from 'moment';
import Dropzone from "react-dropzone";
// Categorization imports
import { getCategories } from "../../../features/entities/categories/categorySlice";
import { getCategorySubCategories } from "features/entities/subcategories/subcategorySlice";
import { getSubCategoryAssetTypes } from "features/entities/types/typeSlice";
import { getTypeAssetMakes } from "features/entities/makes/makeSlice";
import { getMakeAssetModels } from "features/entities/models/modelSlice";
// location imports
import { getRegions } from "../../../features/entities/regions/regionSlice";
import { getRegionBranches } from "../../../features/entities/branches/branchSlice";
import { getBranchRooms } from "../../../features/entities/rooms/roomSlice";
// projects and activities imports
import { getProjects } from "../../../features/entities/projects/projectSlice";
import { getActivities } from "../../../features/entities/activities/activitySlice";
// user imports
import { getUsers } from "../../../features/users/userSlice";
// create office equipment slice import
import { createOfficeEquipment } from "../../../features/register/office-equipment/officeequipSlice";

import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';


const initialValues = {
    barcode: "",
    engraved_number: "",
    asset_card_number: "",
    lpo_no: "",
    cost: "",
    description: "",
    category_class: "",
    category_sub_class: "",
    asset_type: "",
    asset_make: "",
    asset_model: "",
    serial_no: "",
    ac_capacity: "",
    warranty_start_date: null,
    warranty_end_date: null,
    last_service_date: null,
    next_service_date: null,
    region: "",
    branch: "",
    room: "",
    location: "",
    gps_coordinates: "",
    user: "",
    condition: "",
    status: "",
    activity: "",
    project: "",
    remarks: "",
    asset_image: null, // Modified to store the image file
};

const officeEquipmentSchema = yup.object().shape({
    barcode: yup.string().required("Barcode is required"),
    engraved_number: yup.string(),
    asset_card_number: yup.string(),
    lpo_no: yup.string(),
    cost: yup.string(),
    description: yup.string(),
    category_class: yup.string().required("Category Class is required"),
    category_sub_class: yup.string().required("Category Sub Class is required"),
    asset_type: yup.string(),
    asset_make: yup.string(),
    asset_model: yup.string(),
    serial_no: yup.string(),
    ac_capacity: yup.string(),
    warranty_start_date: yup.string().nullable(),
    warranty_end_date: yup.string().nullable(),
    last_service_date: yup.string().nullable(),
    next_service_date: yup.string().nullable(),
    region: yup.string().required("Region is required"),
    branch: yup.string().required("Branch is required"),
    room: yup.string(),
    location: yup.string(),
    gps_coordinates: yup.string(),
    condition: yup.string().required("Condition is required"),
    status: yup.string().required("Status is required"),
    activity: yup.string(),
    project: yup.string().required("Project is required"),
    remarks: yup.string(),
    image: yup.mixed(), // Modified to store the image file
});

const AddOfficeEquipmentform = () => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(true);
    const isNonMobile = useMediaQuery("(min-width: 600px)");
    // alert message
    const { isError, isSuccess, message } = useSelector((state) => state.officeEquipments);
    // location
    const { regions } = useSelector((state) => state.regions);
    const { branches: { regionbranches } } = useSelector((state) => state);
    const { rooms: { branchrooms } } = useSelector((state) => state);
    const { activities } = useSelector((state) => state.activities);
    const { projects } = useSelector((state) => state.projects);
    // categorization
    const { categories } = useSelector((state) => state.categories);
    // select category to use from the list of categories
    const categoryOptions = categories.filter((category) => category.name === "Office Tools & Equipment");
    const { subcategories: { categorysubcategories } } = useSelector((state) => state);
    const { assettypes: { subcategoryassettypes } } = useSelector((state) => state);
    const { assetmakes: { typeassetmakes } } = useSelector((state) => state);
    const { assetmodels: { makeassetmodels } } = useSelector((state) => state);

    // user
    const { users } = useSelector((state) => state.users);

    // Asset condition options
    const conditionOptions = [{ pkid: "NEW", name: "New" }, { pkid: "BAD", name: "Bad" }, { pkid: "GOOD", name: "Good" }, { pkid: "FAIR", name: "Fair" }, { pkid: "BROKEN", name: "Broken" }];

    // Asset status options
    const statusOptions = [{ pkid: "IN_USE", name: "In Use" }, { pkid: "NOT_IN_USE", name: "Not In Use" }, { pkid: "OBSOLETE", name: "Obsolete" }];

    // AC Capacity options
    const ac_capacityOptions = [{ pkid: "BTU_9000", name: "9000 BTU" }, { pkid: "BTU_12000", name: "12000 BTU" }, { pkid: "BTU_18000", name: "18000 BTU" }, { pkid: "BTU_24000", name: "24000 BTU" }, { pkid: "BTU_30000", name: "30000 BTU" }, { pkid: "BTU_32000", name: "32000 BTU" }, { pkid: "BTU_36000", name: "36000 BTU" }, { pkid: "BTU_48000", name: "48000 BTU" }, { pkid: "BTU_60000", name: "60000 BTU" }, { pkid: "BTU_72000", name: "72000 BTU" }, { pkid: "BTU_84000", name: "84000 BTU" }, { pkid: "BTU_96000", name: "96000 BTU" }, { pkid: "BTU_108000", name: "108000 BTU" }, { pkid: "BTU_120000", name: "120000 BTU" }, { pkid: "BTU_132000", name: "132000 BTU" }, { pkid: "BTU_144000", name: "144000 BTU" }, { pkid: "BTU_156000", name: "156000 BTU" }, { pkid: "BTU_168000", name: "168000 BTU" }, { pkid: "BTU_180000", name: "180000 BTU" }, { pkid: "BTU_192000", name: "192000 BTU" }, { pkid: "BTU_204000", name: "204000 BTU" }, { pkid: "BTU_216000", name: "216000 BTU" }, { pkid: "BTU_228000", name: "228000 BTU" }, { pkid: "BTU_240000", name: "240000 BTU" }, { pkid: "BTU_252000", name: "252000 BTU" }, { pkid: "BTU_264000", name: "264000 BTU" }]


    // location and users data fetch
    useEffect(() => {
        dispatch(getRegions());
        dispatch(getUsers());
    }, [dispatch]);

    // categorization data fetch
    useEffect(() => {
        dispatch(getCategories());
    }, [dispatch]);

    // projects and activities data fetch
    useEffect(() => {
        dispatch(getProjects());
        dispatch(getActivities());
    }, [dispatch]);

    // handle categorization cascading
    const handleCategoryChange = (event, formik) => {
        const category_class = event.target.value;
        formik.setFieldValue("category_class", category_class);
        formik.setFieldValue("category_sub_class", ""); // Reset category_sub_class value
        dispatch(getCategorySubCategories(category_class));
    };

    const handleSubCategoryChange = (event, formik) => {
        const category_sub_class = event.target.value;
        formik.setFieldValue("category_sub_class", category_sub_class);
        formik.setFieldValue("asset_type", ""); // Reset asset_type value
        dispatch(getSubCategoryAssetTypes(category_sub_class));
    };

    const handleAssetTypeChange = (event, formik) => {
        const asset_type = event.target.value;
        formik.setFieldValue("asset_type", asset_type);
        formik.setFieldValue("asset_make", ""); // Reset asset_make value
        dispatch(getTypeAssetMakes(asset_type));
    };

    const handleAssetMakeChange = (event, formik) => {
        const asset_make = event.target.value;
        formik.setFieldValue("asset_make", asset_make);
        formik.setFieldValue("asset_model", ""); // Reset asset_model value
        dispatch(getMakeAssetModels(asset_make));
    };

    // handle location cascading
    const handleRegionChange = (event, formik) => {
        const region = event.target.value;
        formik.setFieldValue("region", region);
        formik.setFieldValue("branch", ""); // Reset branch value
        dispatch(getRegionBranches(region));
    };

    const handleBranchChange = (event, formik) => {
        const branch = event.target.value;
        formik.setFieldValue("branch", branch);
        formik.setFieldValue("room", ""); // Reset room value
        dispatch(getBranchRooms(branch));
    };

    // Get GPS coordinates
    function getCoordinates() {
        return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject);
        });
    }

    // Set GPS coordinates to GPS field
    async function handleGetCoordinates(formik) {
        try {
            const position = await getCoordinates();
            const { latitude, longitude } = position.coords;
            formik.setFieldValue('gps_coordinates', `${latitude}, ${longitude}`);
        } catch (error) {
            console.error(error);
        }
    }

    // const handleFileDrop = (acceptedFiles, formik) => {
    //     formik.setFieldValue("image", acceptedFiles[0]);
    // };

    const handleImageDrop = (acceptedFiles, formik) => {
        const file = acceptedFiles[0];
        const reader = new FileReader();

        reader.onload = () => {
            formik.setFieldValue("asset_image", reader.result);
        };
        reader.readAsDataURL(file);
    };

    const handleFormSubmit = async (values, onSubmitProps) => {
        // Format the date fields
        const formattedValues = {
            ...values,
            warranty_start_date: values.warranty_start_date ? moment(values.warranty_start_date).format('YYYY-MM-DD') : null,
            warranty_end_date: values.warranty_end_date ? moment(values.warranty_end_date).format('YYYY-MM-DD') : null,
            last_service_date: values.last_service_date ? moment(values.last_service_date).format('YYYY-MM-DD') : null,
            next_service_date: values.next_service_date ? moment(values.next_service_date).format('YYYY-MM-DD') : null,
        };

        // send new form and barcode data to backend
        await dispatch(createOfficeEquipment(formattedValues));
        onSubmitProps.resetForm(); // Clear the form
        setOpen(true); // Re-open the alert
    };

    return (
        <Box m="10px">
            <Box mb="1rem">
                <Collapse in={open}>
                    <Stack sx={{ width: '100%' }} spacing={2}>
                        {isError && isError.length > 0 ? (
                            <Alert
                                severity="error"
                                action={
                                    <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => { setOpen(false); }}
                                    >
                                        <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                }
                            >
                                <strong>{message}</strong>
                            </Alert>
                        ) : (null)}
                        {isSuccess && isSuccess.length > 0 ? (
                            <Alert
                                severity="success"
                                action={
                                    <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => { setOpen(false); }}
                                    >
                                        <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                }
                            >
                                <strong>{isSuccess}</strong>
                            </Alert>
                        ) : (null)}
                        {message && message.length > 0 ? (
                            <Alert
                                severity="info"
                                action={
                                    <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => { setOpen(false); }}
                                    >
                                        <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                }
                            >
                                <strong>{message}</strong>
                            </Alert>
                        ) : (null)}
                    </Stack>
                </Collapse>
            </Box>
            <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={officeEquipmentSchema}
            >
                {(formik) => (
                    <form onSubmit={formik.handleSubmit}>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            sx={{
                                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                            }}
                        >
                            <TextField
                                fullWidth
                                hidden
                                variant="outlined"
                                type="hidden"
                                label=""
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.location}
                                name="location"
                                error={formik.touched.location && !!formik.errors.location}
                                helperText={formik.touched.location && formik.errors.location}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="hidden"
                                label=""
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.description}
                                name="description"
                                error={formik.touched.description && !!formik.errors.description}
                                helperText={formik.touched.description && formik.errors.description}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                label="Barcode"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.barcode}
                                name="barcode"
                                error={formik.touched.barcode && !!formik.errors.barcode}
                                helperText={formik.touched.barcode && formik.errors.barcode}
                                sx={{ gridColumn: "span 4" }}
                            />
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                label="Engraved Number"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.engraved_number}
                                name="engraved_number"
                                error={formik.touched.engraved_number && !!formik.errors.engraved_number}
                                helperText={formik.touched.engraved_number && formik.errors.engraved_number}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                label="Asset Card Number"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.asset_card_number}
                                name="asset_card_number"
                                error={formik.touched.asset_card_number && !!formik.errors.asset_card_number}
                                helperText={formik.touched.asset_card_number && formik.errors.asset_card_number}
                                sx={{ gridColumn: "span 4" }}
                            />
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                label="LPO Number"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.lpo_no}
                                name="lpo_no"
                                error={formik.touched.lpo_no && !!formik.errors.lpo_no}
                                helperText={formik.touched.lpo_no && formik.errors.lpo_no}
                                sx={{ gridColumn: "span 4" }}
                            />
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="number"
                                label="Purchase Price"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.cost}
                                name="cost"
                                error={formik.touched.cost && !!formik.errors.cost}
                                helperText={formik.touched.cost && formik.errors.cost}
                                sx={{ gridColumn: "span 2" }}
                            />

                            {/* Categorization */}
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                select
                                label="Category"
                                onBlur={formik.handleBlur}
                                onChange={(event) => handleCategoryChange(event, formik)}
                                value={formik.values.category_class}
                                name="category_class"
                                error={formik.touched.category_class && !!formik.errors.category_class}
                                helperText={formik.touched.category_class && formik.errors.category_class}
                                sx={{ gridColumn: "span 4" }}
                            >
                                {categoryOptions.map((category) => (
                                    <MenuItem key={category.pkid} value={category.pkid}>
                                        {category.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                select
                                label="SubCategory"
                                onBlur={formik.handleBlur}
                                onChange={(event) => handleSubCategoryChange(event, formik)}
                                value={formik.values.category_sub_class}
                                name="category_sub_class"
                                error={formik.touched.category_sub_class && !!formik.errors.category_sub_class}
                                helperText={formik.touched.category_sub_class && formik.errors.category_sub_class}
                                sx={{ gridColumn: "span 2" }}
                            >
                                {categorysubcategories.map((categorysubcategory) => (
                                    <MenuItem key={categorysubcategory.pkid} value={categorysubcategory.pkid}>
                                        {categorysubcategory.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                select
                                label="Asset Type"
                                onBlur={formik.handleBlur}
                                onChange={(event) => handleAssetTypeChange(event, formik)}
                                value={formik.values.asset_type}
                                name="asset_type"
                                error={formik.touched.asset_type && !!formik.errors.asset_type}
                                helperText={formik.touched.asset_type && formik.errors.asset_type}
                                sx={{ gridColumn: "span 2" }}
                            >
                                {subcategoryassettypes.map((subcategoryassettype) => (
                                    <MenuItem key={subcategoryassettype.pkid} value={subcategoryassettype.pkid}>
                                        {subcategoryassettype.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                select
                                label="Asset Make"
                                onBlur={formik.handleBlur}
                                onChange={(event) => handleAssetMakeChange(event, formik)}
                                value={formik.values.asset_make}
                                name="asset_make"
                                error={formik.touched.asset_make && !!formik.errors.asset_make}
                                helperText={formik.touched.asset_make && formik.errors.asset_make}
                                sx={{ gridColumn: "span 2" }}
                            >
                                {typeassetmakes.map((typeassetmake) => (
                                    <MenuItem key={typeassetmake.pkid} value={typeassetmake.pkid}>
                                        {typeassetmake.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                select
                                label="Asset Model"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.asset_model}
                                name="asset_model"
                                error={formik.touched.asset_model && !!formik.errors.asset_model}
                                helperText={formik.touched.asset_model && formik.errors.asset_model}
                                sx={{ gridColumn: "span 2" }}
                            >
                                {makeassetmodels.map((makeassetmodel) => (
                                    <MenuItem key={makeassetmodel.pkid} value={makeassetmodel.pkid}>
                                        {makeassetmodel.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                label="Serial Number"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.serial_no}
                                name="serial_no"
                                error={formik.touched.serial_no && !!formik.errors.serial_no}
                                helperText={formik.touched.serial_no && formik.errors.serial_no}
                                sx={{ gridColumn: "span 4" }}
                            />
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                select
                                label="AC Capacity"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.ac_capacity}
                                name="ac_capacity"
                                error={formik.touched.ac_capacity && !!formik.errors.ac_capacity}
                                helperText={formik.touched.ac_capacity && formik.errors.ac_capacity}
                                sx={{ gridColumn: "span 4" }}
                            >
                                {ac_capacityOptions.map((ac_capacity) => (
                                    <MenuItem key={ac_capacity.pkid} value={ac_capacity.pkid}>
                                        {ac_capacity.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <FormikDatePicker
                                fullWidth
                                variant="outlined"
                                label="Warranty Start Date"
                                value={formik.values.warranty_start_date}
                                name="warranty_start_date"
                                onChange={(value) => {
                                    const formattedDate = moment(value).format('YYYY-MM-DD');
                                    formik.setFieldValue('warranty_start_date', formattedDate);
                                }}
                                error={formik.touched.warranty_start_date && !!formik.errors.warranty_start_date}
                                helperText={formik.touched.warranty_start_date && formik.errors.warranty_start_date}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <FormikDatePicker
                                fullWidth
                                variant="outlined"
                                label="Warranty End Date"
                                value={formik.values.warranty_end_date}
                                name="warranty_end_date"
                                onChange={(value) => {
                                    const formattedDate = moment(value).format('YYYY-MM-DD');
                                    formik.setFieldValue('warranty_end_date', formattedDate);
                                }}
                                error={formik.touched.warranty_end_date && !!formik.errors.warranty_end_date}
                                helperText={formik.touched.warranty_end_date && formik.errors.warranty_end_date}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <FormikDatePicker
                                fullWidth
                                variant="outlined"
                                label="Last Service Date"
                                value={formik.values.last_service_date}
                                name="last_service_date"
                                onChange={(value) => {
                                    const formattedDate = moment(value).format('YYYY-MM-DD');
                                    formik.setFieldValue('last_service_date', formattedDate);
                                }}
                                error={formik.touched.last_service_date && !!formik.errors.last_service_date}
                                helperText={formik.touched.last_service_date && formik.errors.last_service_date}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <FormikDatePicker
                                fullWidth
                                variant="outlined"
                                label="Next Service Date"
                                value={formik.values.next_service_date}
                                name="next_service_date"
                                onChange={(value) => {
                                    const formattedDate = moment(value).format('YYYY-MM-DD');
                                    formik.setFieldValue('next_service_date', formattedDate);
                                }}
                                error={formik.touched.next_service_date && !!formik.errors.next_service_date}
                                helperText={formik.touched.next_service_date && formik.errors.next_service_date}
                                sx={{ gridColumn: "span 2" }}
                            />

                            {/* Location */}
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                select
                                label="Region"
                                onBlur={formik.handleBlur}
                                onChange={(event) => handleRegionChange(event, formik)}
                                value={formik.values.region}
                                name="region"
                                error={formik.touched.region && !!formik.errors.region}
                                helperText={formik.touched.region && formik.errors.region}
                                sx={{ gridColumn: "span 4" }}
                            >
                                {regions.map((region) => (
                                    <MenuItem key={region.pkid} value={region.pkid}>
                                        {region.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                select
                                label="Branch"
                                onBlur={formik.handleBlur}
                                onChange={(event) => handleBranchChange(event, formik)}
                                value={formik.values.branch}
                                name="branch"
                                error={formik.touched.branch && !!formik.errors.branch}
                                helperText={formik.touched.branch && formik.errors.branch}
                                sx={{ gridColumn: "span 4" }}
                            >
                                {regionbranches.map((regionbranch) => (
                                    <MenuItem key={regionbranch.pkid} value={regionbranch.pkid}>
                                        {regionbranch.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                select
                                label="Room"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.room}
                                name="room"
                                error={formik.touched.room && !!formik.errors.room}
                                helperText={formik.touched.room && formik.errors.room}
                                sx={{ gridColumn: "span 4" }}
                            >
                                {branchrooms.map((branchroom) => (
                                    <MenuItem key={branchroom.pkid} value={branchroom.pkid}>
                                        {branchroom.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                label="GPS Coordinates"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.gps_coordinates}
                                name="gps_coordinates"
                                error={formik.touched.gps_coordinates && !!formik.errors.gps_coordinates}
                                helperText={formik.touched.gps_coordinates && formik.errors.gps_coordinates}
                                sx={{ gridColumn: "span 4" }}
                            />
                            <Box display="flex" justifyContent="center" mt="5px">
                                <Button fullWidth color="secondary" variant="contained" onClick={() => handleGetCoordinates(formik)}>
                                    Get GPS Coordinates
                                </Button>
                            </Box>
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                select
                                label="User"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.user}
                                name="user"
                                error={formik.touched.user && !!formik.errors.user}
                                helperText={formik.touched.user && formik.errors.user}
                                sx={{ gridColumn: "span 4" }}
                            >
                                {users.map((user) => (
                                    <MenuItem key={user.pkid} value={user.pkid}>
                                        {user.full_name}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                select
                                label="Condition"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.condition}
                                name="condition"
                                error={formik.touched.condition && !!formik.errors.condition}
                                helperText={formik.touched.condition && formik.errors.condition}
                                sx={{ gridColumn: "span 4" }}
                            >
                                {conditionOptions.map((condition) => (
                                    <MenuItem key={condition.pkid} value={condition.pkid}>
                                        {condition.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                select
                                label="Status"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.status}
                                name="status"
                                error={formik.touched.status && !!formik.errors.status}
                                helperText={formik.touched.status && formik.errors.status}
                                sx={{ gridColumn: "span 2" }}
                            >
                                {statusOptions.map((status) => (
                                    <MenuItem key={status.pkid} value={status.pkid}>
                                        {status.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                select
                                label="Activity"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.activity}
                                name="activity"
                                error={formik.touched.activity && !!formik.errors.activity}
                                helperText={formik.touched.activity && formik.errors.activity}
                                sx={{ gridColumn: "span 2" }}
                            >
                                {activities.map((activity) => (
                                    <MenuItem key={activity.pkid} value={activity.pkid}>
                                        {activity.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                select
                                label="Project"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.project}
                                name="project"
                                error={formik.touched.project && !!formik.errors.project}
                                helperText={formik.touched.project && formik.errors.project}
                                sx={{ gridColumn: "span 2" }}
                            >
                                {projects.map((project) => (
                                    <MenuItem key={project.pkid} value={project.pkid}>
                                        {project.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                label="Remarks"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.remarks}
                                name="remarks"
                                error={formik.touched.remarks && !!formik.errors.remarks}
                                helperText={formik.touched.remarks && formik.errors.remarks}
                                sx={{ gridColumn: "span 4" }}
                            />

                            {/* Image and file uploads */}
                            <Dropzone onDrop={(acceptedFiles) => handleImageDrop(acceptedFiles, formik)}>
                                {({ getRootProps, getInputProps }) => (
                                    <Box
                                        {...getRootProps()}
                                        sx={{
                                            border: "2px dashed grey",
                                            borderRadius: "5px",
                                            padding: "20px",
                                            gridColumn: "span 4",
                                        }}
                                    >
                                        <input {...getInputProps()} />
                                        <Box textAlign="center">
                                            {formik.values.asset_image ? (
                                                <div>
                                                    <strong>Selected File:</strong> {formik.values.asset_image.name}
                                                </div>
                                            ) : (
                                                <div>Drag and drop an image file here, or click to select a file</div>
                                            )}
                                        </Box>
                                    </Box>
                                )}
                            </Dropzone>
                        </Box>
                        <Box display="flex" justifyContent="center" mt="20px">
                            <Button fullWidth type="submit" color="secondary" variant="contained">
                                Create Asset
                            </Button>
                        </Box>
                    </form>
                )}
            </Formik>
        </Box>
    );
};

export default AddOfficeEquipmentform;
