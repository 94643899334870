import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import QrCodeScannerOutlinedIcon from '@mui/icons-material/QrCodeScannerOutlined';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import StorageOutlinedIcon from '@mui/icons-material/StorageOutlined';
// import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';

import Header from "../../components/Header";
import PieChart from "../../components/PieChart";
import BarChart from "../../components/BarChart";
import StatBox from "../../components/StatBox";

import TopAppBar from "../global/TopAppBar";

// Data from reports
import { getAssetCaptureReport } from "../../features/reports/asset-captures/assetCaptureSlice";
import { getAssetMovementByLocationReport } from "../../features/reports/movement-location/locationMovementSlice";
import { getAssetVerificationReport } from "../../features/reports/asset-verification/assetVerificationSlice";
// Get all assets
import { getFurnitures } from "../../features/register/furniture/furnitureSlice";
import { getIctequipments } from "../../features/register/ict-equipment/ictequipSlice";
import { getIntangibleAssets } from "../../features/register/intangible/intangibleSlice";
import { getScientificEquipments } from "../../features/register/scientific-equipment/scientificequipSlice";
import { getOfficeEquipments } from "../../features/register/office-equipment/officeequipSlice";
import { getVehicles } from "../../features/register/vehicles/vehicleSlice";

import LineChart from "../../components/LineChart";

const Dashboard = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [setIsSidebar] = useState(true);

    const dispatch = useDispatch();
    // for capture, movement and verification report widgets
    const { assetCaptureReports } = useSelector((state) => state.assetCaptureReports);
    const { assetMovementByLocationReports } = useSelector((state) => state.assetMovementByLocationReports);
    const { assetVerificationReports } = useSelector((state) => state.assetVerificationReports);
    // for total assets widget
    const { officeEquipments } = useSelector((state) => state.officeEquipments);
    const { vehicles } = useSelector((state) => state.vehicles);
    const { furnitures } = useSelector((state) => state.furnitures);
    const { ictequipments } = useSelector((state) => state.ictequipments);
    const { scientificEquips } = useSelector((state) => state.scientificEquips);
    const { intangibleassets } = useSelector((state) => state.intangibleassets);

    // fetch newly captured, moved, verified and all assets
    useEffect(() => {
        const fetchData = async () => {
            try {
                await Promise.all([
                    dispatch(getAssetCaptureReport()),
                    dispatch(getAssetMovementByLocationReport()),
                    dispatch(getAssetVerificationReport()),
                    // require for total number of assets widget
                    dispatch(getOfficeEquipments()),
                    dispatch(getVehicles()),
                    dispatch(getFurnitures()),
                    dispatch(getIctequipments()),
                    dispatch(getScientificEquipments()),
                    dispatch(getIntangibleAssets()),
                ]);
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, [dispatch]);

    // total count for each report
    const uniqueAssetIds = new Set(assetCaptureReports.map(report => report.barcode));
    const totalUniqueCaptured = Array.from(uniqueAssetIds).filter(Boolean).length;
    // const totalCaptured = assetCaptureReports.filter(Boolean).length;
    const totalMovement = assetMovementByLocationReports.filter(Boolean).length;
    const totalVerified = assetVerificationReports.filter(Boolean).length;

    // console.log("scientificequips", scientificEquips);

    // total asset count from all categories
    const totalAssets = officeEquipments.filter(Boolean).length + vehicles.filter(Boolean).length + furnitures.filter(Boolean).length + ictequipments.filter(Boolean).length + intangibleassets.filter(Boolean).length + scientificEquips.filter(Boolean).length

    return (
        <div className="app">
            <main className="content">
                <TopAppBar setIsSidebar={setIsSidebar} />
                <Box m="20px">
                    {/* HEADER */}
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Header title="DASHBOARD" subtitle="Welcome to your dashboard" />
                        <Box>
                            <Button
                                sx={{
                                    backgroundColor: colors.blueAccent[700],
                                    color: colors.grey[100],
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                    padding: "10px 20px",
                                }}
                            >
                                <DownloadOutlinedIcon sx={{ mr: "10px" }} />
                                Reports
                            </Button>
                        </Box>
                    </Box>

                    {/* GRID & CHARTS */}
                    <Box sx={{
                        display: { xs: 'flex', md: 'grid' },
                        gridTemplateColumns: 'repeat(12, 1fr)',
                        gridAutoRows: 'minmax(100px, auto)',
                        gap: 3,
                        flexDirection: 'column',
                    }}

                    // display="grid"
                    // gridTemplateColumns="repeat(12, 1fr)"
                    // gridAutoRows="140px"
                    // gap="20px"

                    >
                        {/* ROW 1 with cards */}
                        <Box
                            gridColumn="span 3"
                            borderRadius="10px"
                            backgroundColor={colors.primary[400]}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <StatBox
                                title={totalMovement}
                                subtitle="Assets Movement"
                                progress=""
                                increase=""
                                icon={
                                    <LocalShippingOutlinedIcon
                                        sx={{ color: colors.greenAccent[600], fontSize: "40px" }}
                                    />
                                }
                            />
                        </Box>
                        <Box
                            gridColumn="span 3"
                            borderRadius="10px"
                            backgroundColor={colors.primary[400]}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <StatBox
                                title={totalUniqueCaptured}
                                subtitle="Newly Captured"
                                progress=""
                                increase=""
                                icon={
                                    <QrCodeScannerOutlinedIcon
                                        sx={{ color: colors.greenAccent[600], fontSize: "40px" }}
                                    />
                                }
                            />
                        </Box>
                        <Box
                            gridColumn="span 3"
                            borderRadius="10px"
                            backgroundColor={colors.primary[400]}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <StatBox
                                title={totalVerified}
                                subtitle="Assets Verified"
                                progress=""
                                increase=""
                                icon={
                                    <VerifiedOutlinedIcon
                                        sx={{ color: colors.greenAccent[600], fontSize: "40px" }}
                                    />
                                }
                            />
                        </Box>
                        <Box
                            gridColumn="span 3"
                            borderRadius="10px"
                            backgroundColor={colors.primary[400]}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <StatBox
                                title={totalAssets}
                                subtitle="Total Assets"
                                progress=""
                                increase=""
                                icon={
                                    <StorageOutlinedIcon
                                        sx={{ color: colors.greenAccent[600], fontSize: "40px" }}
                                    />
                                }
                            />
                        </Box>

                        {/* ROW 2 with Graphs */}
                        <Box
                            gridColumn="span 8"
                            gridRow="span 2"
                            borderRadius="10px"
                            backgroundColor={colors.primary[400]}
                        >
                            <Box
                                mt="25px"
                                p="0 30px"
                                display="flex "
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Box>
                                    <Typography
                                        variant="h5"
                                        fontWeight="600"
                                        color={colors.grey[100]}
                                    >
                                        Assets Breakdown per Location
                                    </Typography>
                                    <Typography
                                        variant="h3"
                                        fontWeight="bold"
                                        color={colors.greenAccent[500]}
                                    >
                                        Total Assets: {totalAssets}
                                    </Typography>
                                </Box>
                                <Box>
                                    <IconButton>
                                        <LocationOnOutlinedIcon
                                            sx={{ fontSize: "40px", color: colors.greenAccent[500] }}
                                        />
                                    </IconButton>
                                </Box>
                            </Box>
                            <Box height="250px" mt="-20px">
                                <BarChart isDashboard={true} />
                            </Box>
                        </Box>
                        <Box
                            gridColumn="span 4"
                            gridRow="span 2"
                            borderRadius="10px"
                            backgroundColor={colors.primary[400]}
                        >
                            <Box
                                mt="25px"
                                p="0 30px"
                                display="flex "
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Box>
                                    <Typography
                                        variant="h5"
                                        fontWeight="600"
                                        color={colors.grey[100]}
                                    >
                                        Assets by Conditions
                                    </Typography>
                                    <Typography
                                        variant="h4"
                                        fontWeight="bold"
                                        color={colors.greenAccent[500]}
                                    >
                                        New, Good, Fair, Bad, Broken
                                    </Typography>
                                </Box>

                            </Box>
                            <Box height="250px" m="-20px 0 0 0">
                                <PieChart isDashboard={true} />
                            </Box>
                        </Box>

                        {/* ROW 3 with Graphs */}
                        {/* <Box
                            gridColumn="span 4"
                            gridRow="span 2"
                            borderRadius="10px"
                            backgroundColor={colors.primary[400]}
                        >
                            <Box
                                mt="25px"
                                p="0 30px"
                                display="flex "
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Box>
                                    <Typography
                                        variant="h5"
                                        fontWeight="600"
                                        color={colors.grey[100]}
                                    >
                                        Assets by Conditions
                                    </Typography>
                                    <Typography
                                        variant="h4"
                                        fontWeight="bold"
                                        color={colors.greenAccent[500]}
                                    >
                                        New, Good, Fair, Bad, Broken
                                    </Typography>
                                </Box>

                            </Box>
                            <Box height="250px" m="-20px 0 0 0">
                                <PieChart isDashboard={true} />
                            </Box>
                        </Box>
                        <Box
                            gridColumn="span 8"
                            gridRow="span 2"
                            borderRadius="10px"
                            backgroundColor={colors.primary[400]}
                        > */}
                        {/* <Box
                                mt="25px"
                                p="0 30px"
                                display="flex "
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Box>
                                    <Typography
                                        variant="h5"
                                        fontWeight="600"
                                        color={colors.grey[100]}
                                    >
                                        Assets Valuation per Region
                                    </Typography>
                                    <Typography
                                        variant="h3"
                                        fontWeight="bold"
                                        color={colors.greenAccent[500]}
                                    >
                                        Total: $59,342.32
                                    </Typography>
                                </Box>
                                <Box>
                                    <IconButton>
                                        <RequestQuoteOutlinedIcon
                                            sx={{ fontSize: "40px", color: colors.greenAccent[500] }}
                                        />
                                    </IconButton>
                                </Box>
                            </Box> */}
                        {/* <Box height="250px" m="-20px 0 0 0">
                                <LineChart isDashboard={true} />
                                <AssetsPage isDashboard={true} />
                            </Box> */}

                    </Box>

                    {/* <Box
                            gridColumn="span 4"
                            gridRow="span 2"
                            borderRadius="10px"
                            backgroundColor={colors.primary[400]}
                            padding="30px"
                        >
                            <Typography
                                variant="h5"
                                fontWeight="600"
                                sx={{ marginBottom: "15px" }}
                            >
                                Assets Distribution
                            </Typography>
                            <Box height="200px">
                                <GeographyChart isDashboard={true} />
                            </Box>
                        </Box> */}
                </Box>
            </main>
        </div>

    );
};

export default Dashboard;
