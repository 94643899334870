import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { Formik } from 'formik';
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box, Button, IconButton, MenuItem, TextField } from "@mui/material";
import Dropzone from "react-dropzone";
// Categorization imports
import { getCategories } from "../../../features/entities/categories/categorySlice";
import { getCategorySubCategories } from "features/entities/subcategories/subcategorySlice";
import { getSubCategoryAssetTypes } from "features/entities/types/typeSlice";
// location imports
import { getRegions } from "../../../features/entities/regions/regionSlice";
import { getRegionBranches } from "../../../features/entities/branches/branchSlice";
import { getBranchRooms } from "../../../features/entities/rooms/roomSlice";
// projects and activities imports
import { getProjects } from "../../../features/entities/projects/projectSlice";
import { getActivities } from "../../../features/entities/activities/activitySlice";
// user imports
import { getUsers } from "../../../features/users/userSlice";
// create ict furniture slice import
import { createFurniture } from "../../../features/register/furniture/furnitureSlice";

import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';


const initialValues = {
  barcode: "",
  engraved_number: "",
  asset_card_number: "",
  lpo_no: "",
  cost: "",
  description: "",
  category_class: "",
  category_sub_class: "",
  asset_type: "",
  material: "",
  region: "",
  branch: "",
  room: "",
  location: "",
  gps_coordinates: "",
  user: "",
  condition: "",
  status: "",
  activity: "",
  project: "",
  remarks: "",
  asset_image: null, // Modified to store the image file
};

const furnitureSchema = yup.object().shape({
  barcode: yup.string().required("Barcode is required"),
  engraved_number: yup.string(),
  asset_card_number: yup.string(),
  lpo_no: yup.string(),
  cost: yup.string(),
  description: yup.string(),
  category_class: yup.string().required("Category Class is required"),
  category_sub_class: yup.string().required("Category Sub Class is required"),
  asset_type: yup.string().required("Asset Type is required"),
  material: yup.string().required("Material is required"),
  region: yup.string().required("Region is required"),
  branch: yup.string().required("Branch is required"),
  room: yup.string(),
  location: yup.string(),
  gps_coordinates: yup.string(),
  user: yup.string(),
  condition: yup.string().required("Condition is required"),
  status: yup.string().required("Status is required"),
  activity: yup.string(),
  project: yup.string().required("Project is required"),
  remarks: yup.string(),
  image: yup.mixed(), // Modified to store the image file
});

const AddFurnitureForm = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);
  const isNonMobile = useMediaQuery("(min-width: 600px)");
  // alert message
  const { isError, isSuccess, message } = useSelector((state) => state.furnitures);
  // location
  const { regions } = useSelector((state) => state.regions);
  const { branches: { regionbranches } } = useSelector((state) => state);
  const { rooms: { branchrooms } } = useSelector((state) => state);
  const { activities } = useSelector((state) => state.activities);
  const { projects } = useSelector((state) => state.projects);
  // categorization
  const { categories } = useSelector((state) => state.categories);
  // select category to use from the list of categories
  const categoryOptions = categories.filter((category) => category.name === "Furniture And Fittings");
  const { subcategories: { categorysubcategories } } = useSelector((state) => state);
  const { assettypes: { subcategoryassettypes } } = useSelector((state) => state);

  // user
  const { users } = useSelector((state) => state.users);

  // Asset condition options
  const conditionOptions = [{ pkid: "NEW", name: "New" }, { pkid: "BAD", name: "Bad" }, { pkid: "GOOD", name: "Good" }, { pkid: "FAIR", name: "Fair" }, { pkid: "BROKEN", name: "Broken" }];

  // Asset status options
  const statusOptions = [{ pkid: "IN_USE", name: "In Use" }, { pkid: "NOT_IN_USE", name: "Not In Use" }, { pkid: "OBSOLETE", name: "Obsolete" }];

  // Material options
  const materialOptions = [{ pkid: "MESH", name: "Mesh" }, { pkid: "FABRIC", name: "Fabric" }, { pkid: "LEATHER", name: "Leather" }, { pkid: "PLASTIC", name: "Plastic" }, { pkid: "WOODEN", name: "Wooden" }, { pkid: "METALLIC", name: "Metalllic" }, { pkid: "GLASS", name: "Glass" }, { pkid: "ALUMINIUM", name: "Aluminium" }, { pkid: "GLASS_WOOD", name: "Glass and Wood" }, { pkid: "OTHER", name: "Other" }];

  // location and users data fetch
  useEffect(() => {
    dispatch(getRegions());
    dispatch(getUsers());
  }, [dispatch]);

  // projects and activities data fetch
  useEffect(() => {
    dispatch(getProjects());
    dispatch(getActivities());
  }, [dispatch]);

  // categorization data fetch
  useEffect(() => {
    dispatch(getCategories());
  }, [dispatch]);

  // handle categorization cascading
  const handleCategoryChange = (event, formik) => {
    const category_class = event.target.value;
    formik.setFieldValue("category_class", category_class);
    formik.setFieldValue("category_sub_class", ""); // Reset category_sub_class value
    dispatch(getCategorySubCategories(category_class));
  };

  const handleSubCategoryChange = (event, formik) => {
    const category_sub_class = event.target.value;
    formik.setFieldValue("category_sub_class", category_sub_class);
    formik.setFieldValue("asset_type", ""); // Reset asset_type value
    dispatch(getSubCategoryAssetTypes(category_sub_class));
  };

  // handle location cascading
  const handleRegionChange = (event, formik) => {
    const region = event.target.value;
    formik.setFieldValue("region", region);
    formik.setFieldValue("branch", ""); // Reset branch value
    dispatch(getRegionBranches(region));
  };

  const handleBranchChange = (event, formik) => {
    const branch = event.target.value;
    formik.setFieldValue("branch", branch);
    formik.setFieldValue("room", ""); // Reset room value
    dispatch(getBranchRooms(branch));
  };

  // Get GPS coordinates
  function getCoordinates() {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    });
  }

  // Set GPS coordinates to GPS field
  async function handleGetCoordinates(formik) {
    try {
      const position = await getCoordinates();
      const { latitude, longitude } = position.coords;
      formik.setFieldValue('gps_coordinates', `${latitude}, ${longitude}`);
    } catch (error) {
      console.error(error);
    }
  }

  // handle image drop
  const handleImageDrop = (acceptedFiles, formik) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();

    reader.onload = () => {
      formik.setFieldValue("asset_image", reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleFormSubmit = async (values, onSubmitProps) => {
    // send new form and barcode data to backend
    await dispatch(createFurniture(values));
    onSubmitProps.resetForm(); // Clear the form
    setOpen(true); // Re-open the alert
  };

  return (
    <Box m="10px">
      <Box mb="1rem">
        <Collapse in={open}>
          <Stack sx={{ width: '100%' }} spacing={2}>
            {isError && isError.length > 0 ? (
              <Alert
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => { setOpen(false); }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                <strong>{message}</strong>
              </Alert>
            ) : (null)}
            {isSuccess && isSuccess.length > 0 ? (
              <Alert
                severity="success"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => { setOpen(false); }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                <strong>{isSuccess}</strong>
              </Alert>
            ) : (null)}
            {message && message.length > 0 ? (
              <Alert
                severity="info"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => { setOpen(false); }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                <strong>{message}</strong>
              </Alert>
            ) : (null)}
          </Stack>
        </Collapse>
      </Box>
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={furnitureSchema}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                hidden
                variant="outlined"
                type="hidden"
                label=""
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.location}
                name="location"
                error={formik.touched.location && !!formik.errors.location}
                helperText={formik.touched.location && formik.errors.location}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="outlined"
                type="hidden"
                label=""
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.description}
                name="description"
                error={formik.touched.description && !!formik.errors.description}
                helperText={formik.touched.description && formik.errors.description}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Barcode"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.barcode}
                name="barcode"
                error={formik.touched.barcode && !!formik.errors.barcode}
                helperText={formik.touched.barcode && formik.errors.barcode}
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Engraved Number"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.engraved_number}
                name="engraved_number"
                error={formik.touched.engraved_number && !!formik.errors.engraved_number}
                helperText={formik.touched.engraved_number && formik.errors.engraved_number}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Asset Card Number"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.asset_card_number}
                name="asset_card_number"
                error={formik.touched.asset_card_number && !!formik.errors.asset_card_number}
                helperText={formik.touched.asset_card_number && formik.errors.asset_card_number}
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="LPO Number"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.lpo_no}
                name="lpo_no"
                error={formik.touched.lpo_no && !!formik.errors.lpo_no}
                helperText={formik.touched.lpo_no && formik.errors.lpo_no}
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="outlined"
                type="number"
                label="Purchase Price"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.cost}
                name="cost"
                error={formik.touched.cost && !!formik.errors.cost}
                helperText={formik.touched.cost && formik.errors.cost}
                sx={{ gridColumn: "span 2" }}
              />

              {/* Categorization */}
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                select
                label="Category"
                onBlur={formik.handleBlur}
                onChange={(event) => handleCategoryChange(event, formik)}
                value={formik.values.category_class}
                name="category_class"
                error={formik.touched.category_class && !!formik.errors.category_class}
                helperText={formik.touched.category_class && formik.errors.category_class}
                sx={{ gridColumn: "span 4" }}
              >
                {categoryOptions.map((category) => (
                  <MenuItem key={category.pkid} value={category.pkid}>
                    {category.name}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                select
                label="SubCategory"
                onBlur={formik.handleBlur}
                onChange={(event) => handleSubCategoryChange(event, formik)}
                value={formik.values.category_sub_class}
                name="category_sub_class"
                error={formik.touched.category_sub_class && !!formik.errors.category_sub_class}
                helperText={formik.touched.category_sub_class && formik.errors.category_sub_class}
                sx={{ gridColumn: "span 2" }}
              >
                {categorysubcategories.map((categorysubcategory) => (
                  <MenuItem key={categorysubcategory.pkid} value={categorysubcategory.pkid}>
                    {categorysubcategory.name}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                select
                label="Asset Type"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.asset_type}
                name="asset_type"
                error={formik.touched.asset_type && !!formik.errors.asset_type}
                helperText={formik.touched.asset_type && formik.errors.asset_type}
                sx={{ gridColumn: "span 2" }}
              >
                {subcategoryassettypes.map((subcategoryassettype) => (
                  <MenuItem key={subcategoryassettype.pkid} value={subcategoryassettype.pkid}>
                    {subcategoryassettype.name}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                select
                label="Material"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.material}
                name="material"
                error={formik.touched.material && !!formik.errors.material}
                helperText={formik.touched.material && formik.errors.material}
                sx={{ gridColumn: "span 4" }}
              >
                {materialOptions.map((material) => (
                  <MenuItem key={material.pkid} value={material.pkid}>
                    {material.name}
                  </MenuItem>
                ))}
              </TextField>

              {/* Location */}
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                select
                label="Region"
                onBlur={formik.handleBlur}
                onChange={(event) => handleRegionChange(event, formik)}
                value={formik.values.region}
                name="region"
                error={formik.touched.region && !!formik.errors.region}
                helperText={formik.touched.region && formik.errors.region}
                sx={{ gridColumn: "span 4" }}
              >
                {regions.map((region) => (
                  <MenuItem key={region.pkid} value={region.pkid}>
                    {region.name}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                select
                label="Branch"
                onBlur={formik.handleBlur}
                onChange={(event) => handleBranchChange(event, formik)}
                value={formik.values.branch}
                name="branch"
                error={formik.touched.branch && !!formik.errors.branch}
                helperText={formik.touched.branch && formik.errors.branch}
                sx={{ gridColumn: "span 4" }}
              >
                {regionbranches.map((regionbranch) => (
                  <MenuItem key={regionbranch.pkid} value={regionbranch.pkid}>
                    {regionbranch.name}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                select
                label="Room"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.room}
                name="room"
                error={formik.touched.room && !!formik.errors.room}
                helperText={formik.touched.room && formik.errors.room}
                sx={{ gridColumn: "span 4" }}
              >
                {branchrooms.map((branchroom) => (
                  <MenuItem key={branchroom.pkid} value={branchroom.pkid}>
                    {branchroom.name}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="GPS Coordinates"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.gps_coordinates}
                name="gps_coordinates"
                error={formik.touched.gps_coordinates && !!formik.errors.gps_coordinates}
                helperText={formik.touched.gps_coordinates && formik.errors.gps_coordinates}
                sx={{ gridColumn: "span 4" }}
              />
              <Box display="flex" justifyContent="center" mt="5px">
                <Button fullWidth color="secondary" variant="contained" onClick={() => handleGetCoordinates(formik)}>
                  Get GPS Coordinates
                </Button>
              </Box>
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                select
                label="User"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.user}
                name="user"
                error={formik.touched.user && !!formik.errors.user}
                helperText={formik.touched.user && formik.errors.user}
                sx={{ gridColumn: "span 4" }}
              >
                {users.map((user) => (
                  <MenuItem key={user.pkid} value={user.pkid}>
                    {user.full_name}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                select
                label="Condition"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.condition}
                name="condition"
                error={formik.touched.condition && !!formik.errors.condition}
                helperText={formik.touched.condition && formik.errors.condition}
                sx={{ gridColumn: "span 4" }}
              >
                {conditionOptions.map((condition) => (
                  <MenuItem key={condition.pkid} value={condition.pkid}>
                    {condition.name}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                select
                label="Status"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.status}
                name="status"
                error={formik.touched.status && !!formik.errors.status}
                helperText={formik.touched.status && formik.errors.status}
                sx={{ gridColumn: "span 2" }}
              >
                {statusOptions.map((status) => (
                  <MenuItem key={status.pkid} value={status.pkid}>
                    {status.name}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                select
                label="Activity"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.activity}
                name="activity"
                error={formik.touched.activity && !!formik.errors.activity}
                helperText={formik.touched.activity && formik.errors.activity}
                sx={{ gridColumn: "span 2" }}
              >
                {activities.map((activity) => (
                  <MenuItem key={activity.pkid} value={activity.pkid}>
                    {activity.name}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                select
                label="Project"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.project}
                name="project"
                error={formik.touched.project && !!formik.errors.project}
                helperText={formik.touched.project && formik.errors.project}
                sx={{ gridColumn: "span 2" }}
              >
                {projects.map((project) => (
                  <MenuItem key={project.pkid} value={project.pkid}>
                    {project.name}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Remarks"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.remarks}
                name="remarks"
                error={formik.touched.remarks && !!formik.errors.remarks}
                helperText={formik.touched.remarks && formik.errors.remarks}
                sx={{ gridColumn: "span 4" }}
              />

              {/* Image and file uploads */}
              <Dropzone onDrop={(acceptedFiles) => handleImageDrop(acceptedFiles, formik)}>
                {({ getRootProps, getInputProps }) => (
                  <Box
                    {...getRootProps()}
                    sx={{
                      border: "2px dashed grey",
                      borderRadius: "5px",
                      padding: "20px",
                      gridColumn: "span 4",
                    }}
                  >
                    <input {...getInputProps()} />
                    <Box textAlign="center">
                      {formik.values.asset_image ? (
                        <div>
                          <strong>Selected File:</strong> {formik.values.asset_image.name}
                        </div>
                      ) : (
                        <div>Drag and drop an image file here, or click to select a file</div>
                      )}
                    </Box>
                  </Box>
                )}
              </Dropzone>
            </Box>
            <Box display="flex" justifyContent="center" mt="20px">
              <Button fullWidth type="submit" color="secondary" variant="contained">
                Create Asset
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default AddFurnitureForm;
