import axios from "axios";

const CREATE_BUILDING_URL = "/api/v1/register/building-asset-create/";
const UPDATE_BUILDING_URL = "/api/v1/register/building-asset-update/barcode:barcode/";
const GET_BUILDINGS_URL = "/api/v1/register/buildings-register/";

//get building buildings
const getBuildings = async (token) => {
    const response = await axios.get(GET_BUILDINGS_URL, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    });
    return response.data
};


//create building Building
const createBuilding = async (buildingData, token) => {
    const config = {
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    };

    const response = await axios.post(CREATE_BUILDING_URL, buildingData, config);
    return response.data;
};


//update building Building
const updateBuilding = async (buildingData, barcode, token) => {
    const config = {
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    };
    const response = await axios.put(UPDATE_BUILDING_URL.replace("barcode", barcode), buildingData, config);
    return response.data;
};

const buildingAPIService = { createBuilding, updateBuilding, getBuildings };

export default buildingAPIService;