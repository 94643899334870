import axios from "axios";

const CREATE_VEHICLE_URL = "/api/v1/register/vehicle-asset-create/";
const UPDATE_VEHICLE_URL = "/api/v1/register/vehicle-asset-update/barcode:barcode/";
const GET_VEHICLES_URL = "/api/v1/register/vehicles-register/";

//get vehicle equipments
const getVehicles = async (token) => {
    const response = await axios.get(GET_VEHICLES_URL, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    });
    return response.data
};


//create vehicle equipment
const createVehicle = async (vehicleData, token) => {
    const config = {
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    };

    const response = await axios.post(CREATE_VEHICLE_URL, vehicleData, config);
    return response.data;
};


//update vehicle equipment
const updateVehicle = async (vehicleData, barcode, token) => {
    const config = {
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    };
    const response = await axios.put(UPDATE_VEHICLE_URL.replace("barcode", barcode), vehicleData, config);
    return response.data;
};

const vehiclesAPIService = { createVehicle, updateVehicle, getVehicles };

export default vehiclesAPIService;