import axios from "axios";

const CREATE_SCIENTIFIC_ASSET_URL = "/api/v1/register/scientific-asset-create/";
const UPDATE_SCIENTIFIC_ASSET_URL = "/api/v1/register/scientific-asset-update/barcode:barcode/";
const GET_SCIENTIFIC_ASSETS_URL = "/api/v1/register/scientific-assets-register/";

//get scientific equipments
const getScientificEquipments = async (token) => {
    const response = await axios.get(GET_SCIENTIFIC_ASSETS_URL, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    });
    return response.data
};


//create scientific
const createScientificEquipment = async (scientificAssetData, token) => {
    const config = {
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    };

    const response = await axios.post(CREATE_SCIENTIFIC_ASSET_URL, scientificAssetData, config);
    return response.data;
};


//update scientific
const updateScientificEquipment = async (scientificAssetData, barcode, token) => {
    const config = {
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    };

    const response = await axios.put(UPDATE_SCIENTIFIC_ASSET_URL.replace("barcode", barcode), scientificAssetData, config);
    return response.data;
};

const scientificAPIService = { createScientificEquipment, updateScientificEquipment, getScientificEquipments };

export default scientificAPIService;