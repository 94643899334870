import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import vehiclesAPIService from "./vehiclesAPIService";


const initialState = {
    vehicles: [],
    vehicle: {},
    isError: false,
    isLoading: false,
    isSuccess: false,
    message: ''
};


//create vehicle 
export const createVehicle = createAsyncThunk('vehicle/create',
    async (vehicleData, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;
            return await vehiclesAPIService.createVehicle(vehicleData, token);
        } catch (error) {
            const message =
                error.message ||
                error.toString() ||
                error.response?.data?.name[0];

            // return thunkAPI.rejectWithValue({ error: message });
            throw new Error(message);
        }
    }
)

//update vehicle 
export const updateVehicle = createAsyncThunk('vehicle/update',
    async ({ vehicleData, barcode }, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;
            return await vehiclesAPIService.updateVehicle(vehicleData, barcode, token);
        } catch (error) {
            const message =
                error.message ||
                error.toString() ||
                error.response?.data?.name[0];

            // return thunkAPI.rejectWithValue({ error: message });
            throw new Error(message);
        }
    }
)

//get all vehicle s
export const getVehicles = createAsyncThunk('vehicles/getAll',
    async (_, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;
            return await vehiclesAPIService.getVehicles(token);
        } catch (error) {
            const message =
                error.message ||
                error.toString() ||
                error.response?.data?.name[0];

            // return thunkAPI.rejectWithValue({ error: message });
            throw new Error(message);
        }
    }
);

export const vehicleSlice = createSlice({
    name: 'vehicle',
    initialState,
    reducers: {
        reset: (state) => initialState
    },
    extraReducers: (builder) => {
        builder
            //create vehicle 
            .addCase(createVehicle.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.isSuccess = false;
                state.message = '';
            })
            .addCase(createVehicle.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.vehicle = action.payload;
                state.message = 'Vehicle  created successfully';
            })
            .addCase(createVehicle.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message;
            })
            //update vehicle 
            .addCase(updateVehicle.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.isSuccess = false;
                state.message = '';
            })
            .addCase(updateVehicle.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.vehicle = action.payload;
                state.message = 'Vehicle  updated successfully';
            })
            .addCase(updateVehicle.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message;
            })
            //get all vehicle s
            .addCase(getVehicles.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getVehicles.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.vehicles = action.payload.results;
                state.message = "";
            })
            .addCase(getVehicles.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message;
            })
    },
});

export const { reset } = vehicleSlice.actions;
export default vehicleSlice.reducer;