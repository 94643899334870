import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import landAPIService from "./landAPIService";


const initialState = {
    land_assets: [],
    land_asset: {},
    isError: false,
    isLoading: false,
    isSuccess: false,
    message: ''
};


//create land asset
export const createLand = createAsyncThunk('land/create',
    async (landData, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;
            return await landAPIService.createLand(landData, token);
        } catch (error) {
            const message =
                error.message ||
                error.toString() ||
                error.response?.data?.name[0];

            // return thunkAPI.rejectWithValue({ error: message });
            throw new Error(message);
        }
    }
)

//update land asset
export const updateLand = createAsyncThunk('land/update',
    async ({ landData, barcode }, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;
            return await landAPIService.updateLand(landData, barcode, token);
        } catch (error) {
            const message =
                error.message ||
                error.toString() ||
                error.response?.data?.name[0];

            // return thunkAPI.rejectWithValue({ error: message });
            throw new Error(message);
        }
    }
)

//get all land assets
export const getLand = createAsyncThunk('land/getAll',
    async (_, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;
            return await landAPIService.getLand(token);
        } catch (error) {
            const message =
                error.message ||
                error.toString() ||
                error.response?.data?.name[0];

            // return thunkAPI.rejectWithValue({ error: message });
            throw new Error(message);
        }
    }
);

export const landSlice = createSlice({
    name: 'land',
    initialState,
    reducers: {
        reset: (state) => initialState
    },
    extraReducers: (builder) => {
        builder
            //create land asset
            .addCase(createLand.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.isSuccess = false;
                state.message = '';
            })
            .addCase(createLand.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.land_asset = action.payload;
                state.message = 'Land asset created successfully';
            })
            .addCase(createLand.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message;
            })
            //update land asset
            .addCase(updateLand.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.isSuccess = false;
                state.message = '';
            })
            .addCase(updateLand.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.land_asset = action.payload;
                state.message = 'Land asset updated successfully';
            })
            .addCase(updateLand.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message;
            })
            //get all land assets
            .addCase(getLand.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getLand.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.land_assets = action.payload.results;
                state.message = "";
            })
            .addCase(getLand.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message;
            })
    },
});

export const { reset } = landSlice.actions;
export default landSlice.reducer;