import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import activityAPIService from "./activityAPIService";


const initialState = {
    activities: [],
    activity: {},
    isError: false,
    isLoading: false,
    isSuccess: false,
    message: ''
};


//create activity
export const createActivity = createAsyncThunk('activities/create',
    async (activityData, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;
            return await activityAPIService.createActivity(activityData, token);
        } catch (error) {
            const message =
                error.message ||
                error.toString() ||
                error.response?.data?.name[0];

            // return thunkAPI.rejectWithValue({ error: message });
            throw new Error(message);
        }
    }
)

//get all activities
export const getActivities = createAsyncThunk('activities/getAll',
    async (_, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;
            return await activityAPIService.getActivities(token);
        } catch (error) {
            const message =
                error.message ||
                error.toString() ||
                error.response?.data?.name[0];

            // return thunkAPI.rejectWithValue({ error: message });
            throw new Error(message);
        }
    }
);

export const activitieslice = createSlice({
    name: 'activity',
    initialState,
    reducers: {
        reset: (state) => initialState
    },
    extraReducers: (builder) => {
        builder
            //create activity
            .addCase(createActivity.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.isSuccess = false;
                state.message = '';
            })
            .addCase(createActivity.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.activity = action.payload;
                state.message = 'Activity created successfully';
            })
            .addCase(createActivity.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message;
            })
            //get all activities
            .addCase(getActivities.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getActivities.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.activities = action.payload;
                state.message = "";
            })
            .addCase(getActivities.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message;
            })
    },
});

export const { reset } = activitieslice.actions;
export default activitieslice.reducer;