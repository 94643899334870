import axios from "axios";

const CREATE_ACTIVITY_URL = "/api/v1/entities/activities/";
const GET_ACTIVITIES_URL = "/api/v1/entities/activities/";

//get activities
const getActivities = async (token) => {
    const response = await axios.get(GET_ACTIVITIES_URL, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    });
    return response.data
};


//create activity
const createActivity = async (activityData, token) => {
    const config = {
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    };

    const response = await axios.post(CREATE_ACTIVITY_URL, activityData, config);
    return response.data;
};

const activityAPIService = { createActivity, getActivities };

export default activityAPIService;