import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import scientificAPIService from "./scientificequipAPIService";


const initialState = {
    scientificEquips: [],
    scientificEquip: {},
    isError: false,
    isLoading: false,
    isSuccess: false,
    message: ''
};


//create scientific
export const createScientificEquipment = createAsyncThunk('scientificequip/create',
    async (scientificAssetData, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;
            return await scientificAPIService.createScientificEquipment(scientificAssetData, token);
        } catch (error) {
            const message =
                error.message ||
                error.toString() ||
                error.response?.data?.name[0];

            // return thunkAPI.rejectWithValue({ error: message });
            throw new Error(message);
        }
    }
)

//update scientific
export const updateScientificEquipment = createAsyncThunk('scientificequip/update',
    async ({ scientificAssetData, barcode }, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;
            return await scientificAPIService.updateScientificEquipment(scientificAssetData, barcode, token);
        } catch (error) {
            const message =
                error.message ||
                error.toString() ||
                error.response?.data?.name[0];

            // return thunkAPI.rejectWithValue({ error: message });
            throw new Error(message);
        }
    }
)

//get all scientific equipment
export const getScientificEquipments = createAsyncThunk('scientificequip/getAll',
    async (_, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;
            return await scientificAPIService.getScientificEquipments(token);
        } catch (error) {
            const message =
                error.message ||
                error.toString() ||
                error.response?.data?.name[0];

            // return thunkAPI.rejectWithValue({ error: message });
            throw new Error(message);
        }
    }
);

export const scientificEquipSlice = createSlice({
    name: 'scientificEquip',
    initialState,
    reducers: {
        reset: (state) => initialState
    },
    extraReducers: (builder) => {
        builder
            //create scientific
            .addCase(createScientificEquipment.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.isSuccess = false;
                state.message = '';
            })
            .addCase(createScientificEquipment.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.scientificEquip = action.payload;
                state.message = 'Scientific equipment created successfully';
            })
            .addCase(createScientificEquipment.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message;
            })
            //update scientific
            .addCase(updateScientificEquipment.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.isSuccess = false;
                state.message = '';
            })
            .addCase(updateScientificEquipment.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.scientificEquip = action.payload;
                state.message = 'Scientific equipment updated successfully';
            })
            .addCase(updateScientificEquipment.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message;
            })
            //get all scientificequip
            .addCase(getScientificEquipments.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getScientificEquipments.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.scientificEquips = action.payload.results;
                state.message = "";
            })
            .addCase(getScientificEquipments.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message;
            })
    },
});

export const { reset } = scientificEquipSlice.actions;
export default scientificEquipSlice.reducer;